import React from 'react';
import { Link } from 'react-router-dom';

const HomeFact = () => {
   return (
      <>
      <section className="about-area gray-bg pt-4 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-10 col-md-12">
                     <div className="about-title mb-20">
                        <h5 className="pink-color">Our Perks</h5>
                        <h1 className="benefits">Benefits Of Physiotherapy</h1>
                     </div>
                     <div className="about-text mission-about-text">
                        <p>Physiotherapy has over the years proved its effectiveness in helping patients in restoring their health and enhancing their physical strength, function and mobility. To further enhance your experience with physiotherapy, our Physiotherapists personally visit you and perform physiotherapy at home. Some of the benefits of getting physiotherapy at home include:-</p><br/>
                     </div>
                     <div>
                     <ul className="professinals-list pt-30">
                              <li>
                                 <i className="fa fa-check"></i>
                                 Enables convenience
                              </li>
                              <li>
                                 <i className="fa fa-check"></i>
                                 Better time management
                              </li>
                              <li>
                                 <i className="fa fa-check"></i>
                                 Family support and supervision
                              </li>
                              <li>
                                 <i className="fa fa-check"></i>
                                 Cost effective
                              </li>
                           </ul>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 d-lg-none d-xl-block">
                     <div className="mv-right-img pos-rel mb-30 Physiotherpy_img">
                        <img src="img/about/myright-img-2.jpg" alt=""/>
                     </div>
                     {/* <div className="testi-quato-icon about-icon-white d-none d-xl-block">
                        <img src="img/about/about-icon-white.png" alt=""/>
                     </div> */}
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeFact;