import React, { useState } from "react";
import CommonSectionArea from "../../../components/CommonSectionArea/CommonSectionArea";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

const AboutArea = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen}
            videoId="I3u3lFA9GX4" onClose={() => setOpen(false)} /> */}

      <CommonSectionArea
        area_header={
          <div
            className="about-left-side pos-rel mb-30"
            style={{ paddingtop: "20px" }}
          >
            <div className="about-front-img pos-rel">
              <img src="img/about/about-img1.jpg" alt="" />
              <button
                onClick={() => setOpen(true)}
                className="popup-video about-video-btn white-video-btn"
              >
                <i className="fas fa-play"></i>
              </button>
            </div>
            <div className="about-shape">
              <img src="img/about/about-shape.png" alt="" />
            </div>
          </div>
        }
      />
      <section className="about-area pb-4 ">
        <div className="container">
          <div className="row">
            {/* <div className="col-xl-6 col-lg-5">{area_header}</div> */}
            <div className="col-xl-12 col-lg-12">
              <div
                className="about-right-side  mb-30"
                style={{ paddingTop: "5px" }}
              >
                <div className="about-title mb-20">
                  {/* <h5>About Us</h5> */}
                  <h1 style={{ fontsize: "42px" }}>
                    The Problem We Aim To Tackle
                  </h1>
                </div>
                <div className="about-text mb-20">
                  <p>
                    The decentralized nature of healthcare services has long
                    been a source of frustration for individuals and families
                    seeking reliable, efficient, and accessible care. Bulaao
                    serves as a revolutionary response, providing a single,
                    user-friendly platform that unifies healthcare facilities
                    under one comprehensive umbrella. Picture a world where the
                    complexities of post-hospitalization care are simplified
                    through a centralized approach. Bulaao ensures that patients
                    and their families can effortlessly navigate through a
                    curated selection of qualified healthcare professionals, all
                    vetted for their expertise, credentials, and dedication to
                    personalized care. Our platform goes beyond being a mere
                    directory; it's a transformative ecosystem designed to
                    streamline the entire healthcare experience. Whether you're
                    in need of post-operative support, rehabilitation services,
                    or ongoing medical assistance, Bulaao is your reliable
                    destination. We understand the challenges families face in
                    finding trustworthy healthcare providers, especially in
                    urgent situations. Bulaao seeks to eliminate this stress by
                    offering a solution that is not just convenient but
                    fundamentally redefines the way we approach healthcare. Our
                    platform is not merely a service; it's a commitment to
                    bringing order and accessibility to the decentralized
                    healthcare landscape.
                  </p>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="problem_heading">
        <h1>The Problem We Aim To Tackle</h1>
      </div>
      <div className="problem_aim">
        <span>
          The decentralized nature of healthcare services has long been a source
          of frustration for individuals and families seeking reliable,
          efficient, and accessible care. Bulaao serves as a revolutionary
          response, providing a single, user-friendly platform that unifies
          healthcare facilities under one comprehensive umbrella. Picture a
          world where the complexities of post-hospitalization care are
          simplified through a centralized approach. Bulaao ensures that
          patients and their families can effortlessly navigate through a
          curated selection of qualified healthcare professionals, all vetted
          for their expertise, credentials, and dedication to personalized care.
          Our platform goes beyond being a mere directory; it's a transformative
          ecosystem designed to streamline the entire healthcare experience.
          Whether you're in need of post-operative support, rehabilitation
          services, or ongoing medical assistance, Bulaao is your reliable
          destination. We understand the challenges families face in finding
          trustworthy healthcare providers, especially in urgent situations.
          Bulaao seeks to eliminate this stress by offering a solution that is
          not just convenient but fundamentally redefines the way we approach
          healthcare. Our platform is not merely a service; it's a commitment to
          bringing order and accessibility to the decentralized healthcare
          landscape.
        </span>
      </div> */}
    </>
  );
};

export default AboutArea;
