import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/Shared/Footer';
// import HomeFact from '../Home/Home/HomeFact/HomeFact';
import HomeCta from '../Home/Home/HomeCta/HomeCta';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import HomeOurTeam from '../Home/Home/HomeOurTeam/HomeOurTeam';
import HomeFourHowWorks from '../HomeFour/HomeFourHowWorks/HomeFourHowWorks';
// import AboutAnalysis from './AboutAnalysis/AboutAnalysis';
import AboutAppoinment from './AboutAppoinment/AboutAppoinment';
import AboutArea from './AboutArea/AboutArea';
import AboutCounter from './AboutCounter/AboutCounter';
// import AboutTestimonial from './AboutTestimonial/AboutTestimonial';

const AboutUs = () => {
   return (
      <>
         <HomeHeader/>
         <CommonPageHeader title="About Us" subtitle="About" />
         <AboutArea/>
         <AboutCounter/>
         <AboutAppoinment/>
         <HomeFourHowWorks/>
         <HomeCta/>
         <HomeOurTeam/>
         <Footer/>
      </>
   );
};

export default AboutUs;