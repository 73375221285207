import React, { useEffect, useState } from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import BookAForm from './BookAForm/BookAForm';
import Footer from '../../components/Shared/Footer';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import PaymentForm from './BookAForm/PaymentForm';

const BookASession = () => {
    useEffect(()=>{
        const isReturnUrl = async () => {
            const redirect_status = new URLSearchParams(window.location.search).get(
                "redirect_status"
            );

            if(redirect_status && redirect_status === 'succeeded'){
                setOrderCompleted(true);
            }
        }

        isReturnUrl();
    })
    const [orderCompleted, setOrderCompleted] = useState(false);
    const [orderDetails, setOrderDetails] = useState({
        currency: null,
        itemName: null,
        price: null,
        orderID: null,
        customer_name: null,
        customer_email: null,
    });

    const handleOrderCompletion = (orderDetails) => {
        setOrderDetails(orderDetails);
        setOrderCompleted(true);

        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <HomeHeader/>
            {!orderCompleted ? (
                <>
                    <CommonPageHeader title="Book A Session" subtitle="Book A Session" />
                    <BookAForm onOrderCompletion={handleOrderCompletion}/>
                </>
            ) : (
                <PaymentForm {...orderDetails} />
            )}
            <Footer/>
        </>
    );
};

export default BookASession;