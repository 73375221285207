import React from 'react';

const ServicesTwoAbout = () => {
    return (
        <>
            <section className="about-area pt-120 pb-90">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="section-title section-title-m-0 mb-30 pos-rel text-end">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-right" src="img/section/section-back-icon.png" alt="" />
                                </div>
                                <div className="section-text section-text-small pos-rel">
                                    <h5>health care facility</h5>
                                    <h1>Would you rather stay at home than go into a health care facility?</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="facalty-text mb-30">
                                <p>At Bulaao Clinic, we are dedicated to providing a comprehensive range of physiotherapy services to meet your specific needs. Our orthopedic rehabilitation programs are tailored to address joint and musculoskeletal issues, whether you're recovering from surgery or managing a chronic condition. If you're an athlete, our sports injury rehabilitation will help you recover faster and enhance your performance. We also specialize in neurological rehabilitation for patients with conditions like stroke, multiple sclerosis, and spinal cord injuries.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServicesTwoAbout;