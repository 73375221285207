import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { PAYMENT_BASE_URL } from '../../../config';

const PaymentForm = ({
  currency,
  itemName,
  price,
  orderID,
  customer_name,
  customer_email,
}) => {
  const navigate = useNavigate();

  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);
  const [customerID, setCustomerID] = useState(null);

  useEffect(() => {
    const fetchStripe = async () => {
      const stripeObject = await loadStripe('pk_live_51Lje3sSBdpMuL6lqzH0ou0o1mFFmMfX2Y31BLrEEM3ciBNKkSjknaZdPHBB0BevpYv2QUQDk0GSpeu69lCDCEcPE00mckru8qu'); //live
      // const stripeObject = await loadStripe('pk_test_51Lje3sSBdpMuL6lq1w66F6ieVl6ove8zJkHEqYTV97GSki5vqPhY7MtV269fRdSkkAsluusxWiYRYn6QeCAJMd9P006I4aXIGK'); //test
      setStripe(stripeObject);
    };

    fetchStripe();
  }, []);

  useEffect(() => {
    fetchPaymentIntent();
  }, []);

  const fetchPaymentIntent = async () => {
    if(!itemName || !orderID){
      showMessage("Order Id missing", true);
      return;
    }
    try {
      const response = await fetch(`${PAYMENT_BASE_URL}/payment_init.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          request_type: "create_payment_intent",
          currency: currency,
          itemname: itemName,
          price: price,
          order_id: orderID,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const { id, clientSecret } = await response.json();
  
      setClientSecret(clientSecret);
      setPaymentIntentId(id);
      setIsProcessing(false);
    } catch (error) {
      console.error("Error fetching payment intent:", error);
    }
  };

  useEffect(() => {
    if (clientSecret && !elements) {
      initializeElements();
    }
  }, [clientSecret, elements]);

  const initializeElements = () => {
    const appearance = {
      theme: "stripe",
      rules: {
        ".Label": {
          fontWeight: "bold",
          textTransform: "uppercase",
        },
      },
    };

    const elementsInstance = stripe.elements({ clientSecret, appearance });
    const paymentElement = elementsInstance.create("payment");
    paymentElement.mount("#paymentElement");

    setElements(elementsInstance);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`${PAYMENT_BASE_URL}/payment_init.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          request_type: "create_customer",
          payment_intent_id: paymentIntentId,
          name: customer_name,
          email: customer_email,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const { id, customer_id } = await response.json();
      setCustomerID(id);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href + "?customer_id=" + customer_id + "&order_id=" + orderID,
        },
      });
    
      if (error.type === "card_error" || error.type === "validation_error") {
        showMessage(error.message, true);
      } else {
        showMessage("An unexpected error occured.", true);
      }
  
      // showMessage("Payment successful!");
    } catch (error) {
      showMessage(error.message || "An unexpected error occurred.", true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkStatus = async () => {
      const urlClientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      const urlCustomerId = new URLSearchParams(window.location.search).get(
        "customer_id"
      );

      const urlOrderId = new URLSearchParams(window.location.search).get(
        "order_id"
      );

      if (!urlClientSecret) {
        return;
      }

      try {
        const { paymentIntent } = await stripe.retrievePaymentIntent(urlClientSecret);
        if (paymentIntent) {
          switch (paymentIntent.status) {
            case "succeeded":
              showMessage("Your payment is processing.");
              const response = await fetch(`${PAYMENT_BASE_URL}/payment_init.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  request_type: "payment_insert",
                  payment_intent: paymentIntent,
                  customer_id: urlCustomerId,
                  order_id: urlOrderId
                }),
              });

              const data = await response.json();
              // console.log(data);  
              if (data.payment_id) {
                showMessage("Success");
                navigate(`/thankyou?txn_id=${data.payment_id}`);
                // window.location.href = APP_BASE_URL + "/thankyou?txn_id=" + data.payment_id;
              } else {
                console.log(data.error);
                showMessage(data.error, true);
              }
              break;
            case "processing":
              showMessage("Your payment is processing.");
              break;
            case "requires_payment_method":
              showMessage("Your payment was not successful, please try again.", true);
              break;
            default:
              showMessage("Something went wrong.", true);
              break;
          }
        } else {
          showMessage("Something went wrong.", true);
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    };

    checkStatus();
  }, [stripe, customerID]);

  const showMessage = (messageText, isError = false) => {
    const paymentResponse = document.getElementById('paymentResponse');
  
    if (paymentResponse) {
      paymentResponse.style.display = 'block';
      paymentResponse.textContent = messageText;
      // paymentResponse.style.display = isError ? 'block' : 'none';
    }
  
    if (isError) {
      setTimeout(function () {
        paymentResponse.style.display = 'none';
        paymentResponse.textContent = messageText;
      }, 5000);
      console.error(messageText);
    } else {
      console.log(messageText);
    }
  };

  return (
    <div className='payment_container'>
      <div className='payment_card'>
        <form className='payment_form' onSubmit={handleSubmit}>
          <div id="paymentResponse"></div>
          <div id="paymentElement"></div>
          <div className="contact-btn text-center mt-5">
            {isProcessing ? 
            <button className='primary_btn btn-icon ml-0' type="button" disabled={true}>Processing</button> 
            :
            <button className='primary_btn btn-icon ml-0' type="submit" disabled={!stripe}><span>₹</span>
              {!isLoading ? "Pay" : "loading" }
            </button>
            }
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentForm;