import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import axios from 'axios';
import { API_BASE_URL } from '../../../config';
import { FaSpinner } from 'react-icons/fa';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";

const BookAForm = ({ onOrderCompletion }) => {
   const navigate = useNavigate();
   const tomorrow = new Date();
   tomorrow.setDate(tomorrow.getDate() + 1);

   const [loading, setLoading] = useState(false);
   const [formData, setFormData] = useState({
      name: '',
      age: '',
      sex: '',
      prescription: '',
      address: '',
      mobile: '',
      email: '',
      state: '',
      city: '',
      date: '',
      time: '',
      package: '',
      pay_met: '',
   });

   const [errors, setErrors] = useState({});

   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
         ...formData,
         [name]: value,
      });

      setErrors({
         ...errors,
         [name]: undefined,
      });
   };

   const handleFlatpickrChange = (selectedDates, dateStr, instance) => {
      setFormData({
        ...formData,
        date: dateStr,
      });

      setErrors({
         ...errors,
         date: undefined,
      });
    };

    const handleTimeChange = (selectedDates, dateStr, instance) => {
      setFormData({
        ...formData,
        time: dateStr,
      });

      setErrors({
         ...errors,
         time: undefined,
      });
    };

   const validateForm = () => {
      const newErrors = {};

      if (!formData.name.trim()) {
         newErrors.name = 'Name field is required!';
      }
      if (!formData.age.trim()) {
         newErrors.age = 'Age field is required!';
      }
      if (!formData.sex.trim()) {
         newErrors.sex = 'Please select Gender!';
      }
      if (!formData.address.trim()) {
         newErrors.address = 'Address field is required!';
      }
      if (!formData.mobile.trim()) {
         newErrors.mobile = 'Phone no is required!';
      }
      if (!formData.email.trim()) {
         newErrors.email = 'Email field is required!';
      }
      if (!formData.state.trim()) {
         newErrors.state = 'State is required!';
      }
      if (!formData.city.trim()) {
         newErrors.city = 'City is required!';
      }
      if (!formData.date.trim()) {
         newErrors.date = 'Please select Date!';
      }
      if (!formData.time.trim()) {
         newErrors.time = 'Please select Time!';
      }
      if (!formData.package.trim()) {
         newErrors.package = 'Please select Package!';
      }
      if (!formData.pay_met.trim()) {
         newErrors.pay_met = 'Please select Payment Method!';
      }

      setErrors(newErrors);

      let firstErrorField = Object.keys(newErrors)[0];
      firstErrorField = document.getElementsByName(firstErrorField)[0];
      if(firstErrorField) firstErrorField.focus();

      return Object.keys(newErrors).length === 0;
   };

   const handleSubmit = async (e) => {
      e.preventDefault();

      if(validateForm()){
         try {
            setLoading(true);

            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(formData)
            };
          
            fetch(`${API_BASE_URL}/create-order`, requestOptions)
              .then(response => response.json())
              .then(data => {
                  if (!data.errors) {
                      if (formData.pay_met === 'online') {
                          onOrderCompletion({
                              currency: 'INR',
                              itemName: data.package_id,
                              price: data.price,
                              orderID: data.orderId,
                              customer_name: formData.name,
                              customer_email: formData.email,
                          });
                      } else {
                          navigate(`/thankyou?order_id=${data.orderId}`);
                      }
                  } else {
                      console.log(data.errors);
                  }
              })
              .catch(error => {
                  console.error('Error:', error);
              })
              .finally(()=>{
                setLoading(false);
              });
         }
         catch (error) {
            console.log(error);
         }
      }
   };

   return (
     <>
       <section className="contact-form-area gray-bg pt-40 pb-100">
         <div className="container">
           <div className="form-wrapper">
             <div className="row align-items-center">
               <div className="col-xl-8 col-lg-8">
                 <div className="section-title mb-55">
                   <p>
                     <span></span>Schedule a Physio Session
                   </p>
                   <h1>Connect for Physiotherapy</h1>
                 </div>
               </div>
             </div>
             <div className="contact-form">
               <form id="contact-form" onSubmit={handleSubmit}>
                 <div className="row">
                   <div className="col-lg-12">
                     <div className="form-box user-icon mb-30">
                       <div>
                         <h3>Patient Details: </h3>
                       </div>
                     </div>
                   </div>
                   <div className="col-lg-6">
                     <div className="form-box user-icon mb-30">
                       <input
                         className="patientname"
                         type="text"
                         name="name"
                         placeholder="Enter Your Name"
                         value={formData.name}
                         onChange={handleInputChange}
                       />
                       {errors.name && (
                         <div className="error-message">{errors.name}</div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-6">
                     <div className="form-box user-icon mb-30">
                       <input
                         className="patientname1"
                         type="number"
                         name="age"
                         placeholder="Enter Your Age"
                         value={formData.age}
                         onChange={handleInputChange}
                       />
                       {errors.age && (
                         <div className="error-message">{errors.age}</div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-6">
                     <div className="form-box user-icon mb-30 d-flex ">
                       <div className="radio-btn">
                         <input
                           className=""
                           type="Radio"
                           id="male"
                           name="sex"
                           value="Male"
                           checked={formData.sex === "Male"}
                           onChange={handleInputChange}
                         />
                         <label className="gender" htmlFor="male">
                           Male
                         </label>
                       </div>
                       <div className="radio-btn">
                         <input
                           className=""
                           type="Radio"
                           id="female"
                           name="sex"
                           value="Female"
                           checked={formData.sex === "Female"}
                           onChange={handleInputChange}
                         />
                         <label className="gender" htmlFor="female">
                           Female
                         </label>
                       </div>
                       <div className="radio-btn">
                         <input
                           className=""
                           type="Radio"
                           id="others"
                           name="sex"
                           value="Others"
                           checked={formData.sex === "Others"}
                           onChange={handleInputChange}
                         />
                         <label className="gender" htmlFor="others">
                           Others
                         </label>
                       </div>

                       {errors.sex && (
                         <div className="error-message">{errors.sex}</div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-6">
                     <div className="form-box subject-icon mb-30">
                       <label className="contact-table">
                         Upload Presciption
                       </label>
                       <input
                         className="Upload-Presciption "
                         type="file"
                         name="prescription"
                         placeholder="Upload Presciption"
                         value={formData.prescription}
                         onChange={handleInputChange}
                       />
                       {errors.prescription && (
                         <div className="error-message">
                           {errors.prescription}
                         </div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-6">
                     <div className="form-box subject-icon mb-30">
                       <input
                         type="text"
                         name="address"
                         placeholder="Your Address"
                         value={formData.address}
                         onChange={handleInputChange}
                       />
                       {errors.address && (
                         <div className="error-message">{errors.address}</div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-6">
                     <div className="form-box subject-icon mb-30">
                       <input
                         type="number"
                         name="mobile"
                         placeholder="Your Whatsapp Number"
                         value={formData.mobile}
                         onChange={handleInputChange}
                       />
                       <img
                         src="img/icon/Whatsapp_icon.png"
                         alt=""
                         className="whatsapp_icon"
                       />
                       {errors.mobile && (
                         <div className="error-message">{errors.mobile}</div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-4">
                     <div className="form-box subject-icon mb-30">
                       <input
                         type="text"
                         name="email"
                         placeholder="Your Email Address"
                         value={formData.email}
                         onChange={handleInputChange}
                       />
                       {errors.email && (
                         <div className="error-message">{errors.email}</div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-4">
                     <div className="form-box mb-30">
                       <input
                         list="browsers"
                         name="state"
                         placeholder="Choose Your State"
                         value={formData.state}
                         onChange={handleInputChange}
                       />
                       {errors.state && (
                         <div className="error-message">{errors.state}</div>
                       )}
                       <datalist id="browsers">
                         <option value="Andhra Pradesh">Andhra Pradesh</option>
                         <option value="Andaman and Nicobar Islands">
                           Andaman and Nicobar Islands
                         </option>
                         <option value="Arunachal Pradesh">
                           Arunachal Pradesh
                         </option>
                         <option value="Assam">Assam</option>
                         <option value="Bihar">Bihar</option>
                         <option value="Chandigarh">Chandigarh</option>
                         <option value="Chhattisgarh">Chhattisgarh</option>
                         <option value="Dadar and Nagar Haveli">
                           Dadar and Nagar Haveli
                         </option>
                         <option value="Daman and Diu">Daman and Diu</option>
                         <option value="Delhi">Delhi</option>
                         <option value="Lakshadweep">Lakshadweep</option>
                         <option value="Puducherry">Puducherry</option>
                         <option value="Goa">Goa</option>
                         <option value="Gujarat">Gujarat</option>
                         <option value="Haryana">Haryana</option>
                         <option value="Himachal Pradesh">
                           Himachal Pradesh
                         </option>
                         <option value="Jammu and Kashmir">
                           Jammu and Kashmir
                         </option>
                         <option value="Jharkhand">Jharkhand</option>
                         <option value="Karnataka">Karnataka</option>
                         <option value="Kerala">Kerala</option>
                         <option value="Madhya Pradesh">Madhya Pradesh</option>
                         <option value="Maharashtra">Maharashtra</option>
                         <option value="Manipur">Manipur</option>
                         <option value="Meghalaya">Meghalaya</option>
                         <option value="Mizoram">Mizoram</option>
                         <option value="Nagaland">Nagaland</option>
                         <option value="Odisha">Odisha</option>
                         <option value="Punjab">Punjab</option>
                         <option value="Rajasthan">Rajasthan</option>
                         <option value="Sikkim">Sikkim</option>
                         <option value="Tamil Nadu">Tamil Nadu</option>
                         <option value="Telangana">Telangana</option>
                         <option value="Tripura">Tripura</option>
                         <option value="Uttar Pradesh">Uttar Pradesh</option>
                         <option value="Uttarakhand">Uttarakhand</option>
                         <option value="West Bengal">West Bengal</option>
                       </datalist>
                     </div>
                   </div>
                   <div className="col-lg-4">
                     <div className="form-box mb-30">
                       <input
                         list="cities"
                         name="city"
                         placeholder="Choose Your City"
                         value={formData.city}
                         onChange={handleInputChange}
                       />
                       {errors.city && (
                         <div className="error-message">{errors.city}</div>
                       )}
                       <datalist id="cities">
                         <option value="Adilabad">Adilabad</option>
                         <option value="Agra">Agra</option>
                         <option value="Ahmedabad">Ahmedabad</option>
                         <option value="Ahmedabad">Ahmedabad</option>
                         <option value="Ahmednagar">Ahmednagar</option>
                         <option value="Aizawl">Aizawl</option>
                         <option value="Ajitgarh(Mohali)">
                           Ajitgarh (Mohali)
                         </option>
                         <option value="Ajmer">Ajmer</option>
                         <option value="Akola">Akola</option>
                         <option value="Alappuzha">Alappuzha</option>
                         <option value="Aligarh">Aligarh</option>
                         <option value="Alirajpur">Alirajpur</option>
                         <option value="Allahabad">Allahabad</option>
                         <option value="Almora">Almora</option>
                         <option value="Alwar">Alwar</option>
                         <option value="Ambala">Ambala</option>
                         <option value="AmbedkarNagar">Ambedkar Nagar</option>
                         <option value="Amravati">Amravati</option>
                         <option value="Amrelidistrict">Amreli district</option>
                         <option value="Amritsar">Amritsar</option>
                         <option value="Anand">Anand</option>
                         <option value="Anantapur">Anantapur</option>
                         <option value="Anantnag">Anantnag</option>
                         <option value="Angul">Angul</option>
                         <option value="Anjaw">Anjaw</option>
                         <option value="Anuppur">Anuppur</option>
                         <option value="Araria">Araria</option>
                         <option value="Ariyalur">Ariyalur</option>
                         <option value="Arwal">Arwal</option>
                         <option value="AshokNagar">Ashok Nagar</option>
                         <option value="Auraiya">Auraiya</option>
                         <option value="Aurangabad">Aurangabad</option>
                         <option value="Aurangabad">Aurangabad</option>
                         <option value="Azamgarh">Azamgarh</option>
                         <option value="Badgam">Badgam</option>
                         <option value="Bagalkot">Bagalkot</option>
                         <option value="Bageshwar">Bageshwar</option>
                         <option value="Bagpat">Bagpat</option>
                         <option value="Bahraich">Bahraich</option>
                         <option value="Baksa">Baksa</option>
                         <option value="Balaghat">Balaghat</option>
                         <option value="Balangir">Balangir</option>
                         <option value="Balasore">Balasore</option>
                         <option value="Ballia">Ballia</option>
                         <option value="Balrampur">Balrampur</option>
                         <option value="Banaskantha">Banaskantha</option>
                         <option value="Banda">Banda</option>
                         <option value="Bandipora">Bandipora</option>
                         <option value="BangaloreRural">Bangalore Rural</option>
                         <option value="BangaloreUrban">Bangalore Urban</option>
                         <option value="Banka">Banka</option>
                         <option value="Bankura">Bankura</option>
                         <option value="Banswara">Banswara</option>
                         <option value="Barabanki">Barabanki</option>
                         <option value="Baramulla">Baramulla</option>
                         <option value="Baran">Baran</option>
                         <option value="Bardhaman">Bardhaman</option>
                         <option value="Bareilly">Bareilly</option>
                         <option value="Bargarh(Baragarh)">
                           Bargarh (Baragarh)
                         </option>
                         <option value="Barmer">Barmer</option>
                         <option value="Barnala">Barnala</option>
                         <option value="Barpeta">Barpeta</option>
                         <option value="Barwani">Barwani</option>
                         <option value="Bastar">Bastar</option>
                         <option value="Basti">Basti</option>
                         <option value="Bathinda">Bathinda</option>
                         <option value="Beed">Beed</option>
                         <option value="Begusarai">Begusarai</option>
                         <option value="Belgaum">Belgaum</option>
                         <option value="Bellary">Bellary</option>
                         <option value="Betul">Betul</option>
                         <option value="Bhadrak">Bhadrak</option>
                         <option value="Bhagalpur">Bhagalpur</option>
                         <option value="Bhandara">Bhandara</option>
                         <option value="Bharatpur">Bharatpur</option>
                         <option value="Bharuch">Bharuch</option>
                         <option value="Bhavnagar">Bhavnagar</option>
                         <option value="Bhilwara">Bhilwara</option>
                         <option value="Bhind">Bhind</option>
                         <option value="Bhiwani">Bhiwani</option>
                         <option value="Bhojpur">Bhojpur</option>
                         <option value="Bhopal">Bhopal</option>
                         <option value="Bidar">Bidar</option>
                         <option value="Bijapur">Bijapur</option>
                         <option value="Bijapur">Bijapur</option>
                         <option value="Bijnor">Bijnor</option>
                         <option value="Bikaner">Bikaner</option>
                         <option value="Bilaspur">Bilaspur</option>
                         <option value="Bilaspur">Bilaspur</option>
                         <option value="Birbhum">Birbhum</option>
                         <option value="Bishnupur">Bishnupur</option>
                         <option value="Bokaro">Bokaro</option>
                         <option value="Bongaigaon">Bongaigaon</option>
                         <option value="Boudh(Bauda)">Boudh (Bauda)</option>
                         <option value="Budaun">Budaun</option>
                         <option value="Bulandshahr">Bulandshahr</option>
                         <option value="Buldhana">Buldhana</option>
                         <option value="Bundi">Bundi</option>
                         <option value="Burhanpur">Burhanpur</option>
                         <option value="Buxar">Buxar</option>
                         <option value="Cachar">Cachar</option>
                         <option value="CentralDelhi">Central Delhi</option>
                         <option value="Chamarajnagar">Chamarajnagar</option>
                         <option value="Chamba">Chamba</option>
                         <option value="Chamoli">Chamoli</option>
                         <option value="Champawat">Champawat</option>
                         <option value="Champhai">Champhai</option>
                         <option value="Chandauli">Chandauli</option>
                         <option value="Chandel">Chandel</option>
                         <option value="Chandigarh">Chandigarh</option>
                         <option value="Chandrapur">Chandrapur</option>
                         <option value="Changlang">Changlang</option>
                         <option value="Chatra">Chatra</option>
                         <option value="Chennai">Chennai</option>
                         <option value="Chhatarpur">Chhatarpur</option>
                         <option value="ChhatrapatiShahujiMaharajNagar">
                           Chhatrapati Shahuji Maharaj Nagar{" "}
                         </option>
                         <option value="Chhindwara">Chhindwara</option>
                         <option value="Chikkaballapur">Chikkaballapur</option>
                         <option value="Chikkamagaluru">Chikkamagaluru</option>
                         <option value="Chirang">Chirang</option>
                         <option value="Chitradurga">Chitradurga</option>
                         <option value="Chitrakoot">Chitrakoot</option>
                         <option value="Chittoor">Chittoor</option>
                         <option value="Chittorgarh">Chittorgarh</option>
                         <option value="Churachandpur">Churachandpur</option>
                         <option value="Churu">Churu</option>
                         <option value="Coimbatore">Coimbatore</option>
                         <option value="CoochBehar">Cooch Behar</option>
                         <option value="Cuddalore">Cuddalore</option>
                         <option value="Cuttack">Cuttack</option>
                         <option value="DadraandNagarHaveli">
                           {" "}
                           Dadra and Nagar Haveli{" "}
                         </option>
                         <option value="Dahod">Dahod</option>
                         <option value="DakshinDinajpur">
                           Dakshin Dinajpur
                         </option>
                         <option value="DakshinaKannada">
                           Dakshina Kannada
                         </option>
                         <option value="Daman">Daman</option>
                         <option value="Damoh">Damoh</option>
                         <option value="Dantewada">Dantewada</option>
                         <option value="Darbhanga">Darbhanga</option>
                         <option value="Darjeeling">Darjeeling</option>
                         <option value="Darrang">Darrang</option>
                         <option value="Datia">Datia</option>
                         <option value="Dausa">Dausa</option>
                         <option value="Davanagere">Davanagere</option>
                         <option value="Debagarh(Deogarh)">
                           Debagarh (Deogarh)
                         </option>
                         <option value="Dehradun">Dehradun</option>
                         <option value="Deoghar">Deoghar</option>
                         <option value="Deoria">Deoria</option>
                         <option value="Dewas">Dewas</option>
                         <option value="Dhalai">Dhalai</option>
                         <option value="Dhamtari">Dhamtari</option>
                         <option value="Dhanbad">Dhanbad</option>
                         <option value="Dhar">Dhar</option>
                         <option value="Dharmapuri">Dharmapuri</option>
                         <option value="Dharwad">Dharwad</option>
                         <option value="Dhemaji">Dhemaji</option>
                         <option value="Dhenkanal">Dhenkanal</option>
                         <option value="Dholpur">Dholpur</option>
                         <option value="Dhubri">Dhubri</option>
                         <option value="Dhule">Dhule</option>
                         <option value="DibangValley">Dibang Valley</option>
                         <option value="Dibrugarh">Dibrugarh</option>
                         <option value="DimaHasao">Dima Hasao</option>
                         <option value="Dimapur">Dimapur</option>
                         <option value="Dindigul">Dindigul</option>
                         <option value="Dindori">Dindori</option>
                         <option value="Diu">Diu</option>
                         <option value="Doda">Doda</option>
                         <option value="Dumka">Dumka</option>
                         <option value="Dungapur">Dungapur</option>
                         <option value="Durg">Durg</option>
                         <option value="EastChamparan">East Champaran</option>
                         <option value="EastDelhi">East Delhi</option>
                         <option value="EastGaroHills">East Garo Hills</option>
                         <option value="EastKhasiHills">
                           East Khasi Hills
                         </option>
                         <option value="EastSiang">East Siang</option>
                         <option value="EastSikkim">East Sikkim</option>
                         <option value="EastSinghbhum">East Singhbhum</option>
                         <option value="Eluru">Eluru</option>
                         <option value="Ernakulam">Ernakulam</option>
                         <option value="Erode">Erode</option>
                         <option value="Etah">Etah</option>
                         <option value="Etawah">Etawah</option>
                         <option value="Faizabad">Faizabad</option>
                         <option value="Faridabad">Faridabad</option>
                         <option value="Faridkot">Faridkot</option>
                         <option value="Farrukhabad">Farrukhabad</option>
                         <option value="Fatehabad">Fatehabad</option>
                         <option value="FatehgarhSahib">Fatehgarh Sahib</option>
                         <option value="Fatehpur">Fatehpur</option>
                         <option value="Fazilka">Fazilka</option>
                         <option value="Firozabad">Firozabad</option>
                         <option value="Firozpur">Firozpur</option>
                         <option value="Gadag">Gadag</option>
                         <option value="Gadchiroli">Gadchiroli</option>
                         <option value="Gajapati">Gajapati</option>
                         <option value="Ganderbal">Ganderbal</option>
                         <option value="Gandhinagar">Gandhinagar</option>
                         <option value="Ganganagar">Ganganagar</option>
                         <option value="Ganjam">Ganjam</option>
                         <option value="Garhwa">Garhwa</option>
                         <option value="GautamBuddhNagar">
                           Gautam Buddh Nagar
                         </option>
                         <option value="Gaya">Gaya</option>
                         <option value="Ghaziabad">Ghaziabad</option>
                         <option value="Ghazipur">Ghazipur</option>
                         <option value="Giridih">Giridih</option>
                         <option value="Goalpara">Goalpara</option>
                         <option value="Godda">Godda</option>
                         <option value="Golaghat">Golaghat</option>
                         <option value="Gonda">Gonda</option>
                         <option value="Gondia">Gondia</option>
                         <option value="Gopalganj">Gopalganj</option>
                         <option value="Gorakhpur">Gorakhpur</option>
                         <option value="Gulbarga">Gulbarga</option>
                         <option value="Gumla">Gumla</option>
                         <option value="Guna">Guna</option>
                         <option value="Guntur">Guntur</option>
                         <option value="Gurdaspur">Gurdaspur</option>
                         <option value="Gurgaon">Gurgaon</option>
                         <option value="Gwalior">Gwalior</option>
                         <option value="Hailakandi">Hailakandi</option>{" "}
                         <option value="Hamirpur">Hamirpur</option>{" "}
                         <option value="Hamirpur">Hamirpur</option>{" "}
                         <option value="Hanumangarh">Hanumangarh</option>{" "}
                         <option value="Harda">Harda</option>{" "}
                         <option value="Hardoi">Hardoi</option>{" "}
                         <option value="Haridwar">Haridwar</option>{" "}
                         <option value="Hassan">Hassan</option>{" "}
                         <option value="Haveridistrict">Haveri district</option>{" "}
                         <option value="Hazaribag">Hazaribag</option>{" "}
                         <option value="Hingoli">Hingoli</option>{" "}
                         <option value="Hissar">Hissar</option>{" "}
                         <option value="Hooghly">Hooghly</option>{" "}
                         <option value="Hoshangabad">Hoshangabad</option>{" "}
                         <option value="Hoshiarpur">Hoshiarpur</option>{" "}
                         <option value="Howrah">Howrah</option>{" "}
                         <option value="Hyderabad">Hyderabad</option>{" "}
                         <option value="Hyderabad">Hyderabad</option>
                         <option value="Idukki">Idukki</option>
                         <option value="ImphalEast">Imphal East</option>
                         <option value="ImphalWest">Imphal West</option>
                         <option value="Indore">Indore</option>
                         <option value="Jabalpur">Jabalpur</option>{" "}
                         <option value="Jagatsinghpur">Jagatsinghpur</option>{" "}
                         <option value="JaintiaHills">Jaintia Hills</option>{" "}
                         <option value="Jaipur">Jaipur</option>{" "}
                         <option value="Jaisalmer">Jaisalmer</option>{" "}
                         <option value="Jajpur">Jajpur</option>{" "}
                         <option value="Jalandhar">Jalandhar</option>{" "}
                         <option value="Jalaun">Jalaun</option>{" "}
                         <option value="Jalgaon">Jalgaon</option>{" "}
                         <option value="Jalna">Jalna</option>{" "}
                         <option value="Jalore">Jalore</option>{" "}
                         <option value="Jalpaiguri">Jalpaiguri</option>{" "}
                         <option value="Jammu">Jammu</option>{" "}
                         <option value="Jamnagar">Jamnagar</option>{" "}
                         <option value="Jamtara">Jamtara</option>{" "}
                         <option value="Jamui">Jamui</option>{" "}
                         <option value="Janjgir-Champa">Janjgir-Champa</option>{" "}
                         <option value="Jashpur">Jashpur</option>{" "}
                         <option value="Jaunpurdistrict">
                           Jaunpur district
                         </option>{" "}
                         <option value="Jehanabad">Jehanabad</option>{" "}
                         <option value="Jhabua">Jhabua</option>{" "}
                         <option value="Jhajjar">Jhajjar</option>{" "}
                         <option value="Jhalawar">Jhalawar</option>{" "}
                         <option value="Jhansi">Jhansi</option>{" "}
                         <option value="Jharsuguda">Jharsuguda</option>{" "}
                         <option value="Jhunjhunu">Jhunjhunu</option>{" "}
                         <option value="Jind">Jind</option>{" "}
                         <option value="Jodhpur">Jodhpur</option>{" "}
                         <option value="Jorhat">Jorhat</option>{" "}
                         <option value="Junagadh">Junagadh</option>{" "}
                         <option value="JyotibaPhuleNagar">
                           Jyotiba Phule Nagar
                         </option>
                         <option value="Kabirdham(formerlyKawardha)">
                           {" "}
                           Kabirdham (formerly Kawardha){" "}
                         </option>
                         <option value="Kadapa">Kadapa</option>
                         <option value="Kaimur">Kaimur</option>
                         <option value="Kaithal">Kaithal</option>
                         <option value="Kakinada">Kakinada</option>
                         <option value="Kalahandi">Kalahandi</option>
                         <option value="Kamrup">Kamrup</option>
                         <option value="KamrupMetropolitan">
                           Kamrup Metropolitan
                         </option>
                         <option value="Kanchipuram">Kanchipuram</option>
                         <option value="Kandhamal">Kandhamal</option>
                         <option value="Kangra">Kangra</option>
                         <option value="Kanker">Kanker</option>
                         <option value="Kannauj">Kannauj</option>
                         <option value="Kannur">Kannur</option>
                         <option value="Kanpur">Kanpur</option>
                         <option value="KanshiRamNagar">
                           Kanshi Ram Nagar
                         </option>{" "}
                         <option value="Kanyakumari">Kanyakumari</option>{" "}
                         <option value="Kapurthala">Kapurthala</option>{" "}
                         <option value="Karaikal">Karaikal</option>{" "}
                         <option value="Karauli">Karauli</option>{" "}
                         <option value="KarbiAnglong">Karbi Anglong</option>{" "}
                         <option value="Kargil">Kargil</option>{" "}
                         <option value="Karimganj">Karimganj</option>{" "}
                         <option value="Karimnagar">Karimnagar</option>{" "}
                         <option value="Karnal">Karnal</option>{" "}
                         <option value="Karur">Karur</option>{" "}
                         <option value="Kasaragod">Kasaragod</option>{" "}
                         <option value="Kathua">Kathua</option>{" "}
                         <option value="Katihar">Katihar</option>{" "}
                         <option value="Katni">Katni</option>{" "}
                         <option value="Kaushambi">Kaushambi</option>{" "}
                         <option value="Kendrapara">Kendrapara</option>{" "}
                         <option value="Kendujhar(Keonjhar)">
                           {" "}
                           Kendujhar (Keonjhar){" "}
                         </option>{" "}
                         <option value="Khagaria">Khagaria</option>{" "}
                         <option value="Khammam">Khammam</option>{" "}
                         <option value="Khandwa(EastNimar)">
                           Khandwa (East Nimar)
                         </option>{" "}
                         <option value="Khargone(WestNimar)">
                           {" "}
                           Khargone (West Nimar){" "}
                         </option>{" "}
                         <option value="Kheda">Kheda</option>{" "}
                         <option value="Khordha">Khordha</option>{" "}
                         <option value="Khowai">Khowai</option>{" "}
                         <option value="Khunti">Khunti</option>{" "}
                         <option value="Kinnaur">Kinnaur</option>{" "}
                         <option value="Kishanganj">Kishanganj</option>{" "}
                         <option value="Kishtwar">Kishtwar</option>{" "}
                         <option value="Kodagu">Kodagu</option>{" "}
                         <option value="Koderma">Koderma</option>{" "}
                         <option value="Kohima">Kohima</option>{" "}
                         <option value="Kokrajhar">Kokrajhar</option>{" "}
                         <option value="Kolar">Kolar</option>{" "}
                         <option value="Kolasib">Kolasib</option>{" "}
                         <option value="Kolhapur">Kolhapur</option>{" "}
                         <option value="Kolkata">Kolkata</option>{" "}
                         <option value="Kollam">Kollam</option>{" "}
                         <option value="Koppal">Koppal</option>{" "}
                         <option value="Koraput">Koraput</option>{" "}
                         <option value="Korba">Korba</option>{" "}
                         <option value="Koriya">Koriya</option>{" "}
                         <option value="Kota">Kota</option>{" "}
                         <option value="Kottayam">Kottayam</option>{" "}
                         <option value="Kozhikode">Kozhikode</option>{" "}
                         <option value="Krishna">Krishna</option>{" "}
                         <option value="Kulgam">Kulgam</option>{" "}
                         <option value="Kullu">Kullu</option>{" "}
                         <option value="Kupwara">Kupwara</option>{" "}
                         <option value="Kurnool">Kurnool</option>{" "}
                         <option value="Kurukshetra">Kurukshetra</option>{" "}
                         <option value="Kutch">Kutch</option>
                         <option value="LahaulandSpiti">
                           Lahaul and Spiti
                         </option>{" "}
                         <option value="Lakhimpur">Lakhimpur</option>{" "}
                         <option value="LakhimpurKheri">Lakhimpur Kheri</option>{" "}
                         <option value="Lakhisarai">Lakhisarai</option>{" "}
                         <option value="Lalitpur">Lalitpur</option>{" "}
                         <option value="Latehar">Latehar</option>{" "}
                         <option value="Latur">Latur</option>{" "}
                         <option value="Lawngtlai">Lawngtlai</option>{" "}
                         <option value="Leh">Leh</option>{" "}
                         <option value="Lohardaga">Lohardaga</option>{" "}
                         <option value="Lohit">Lohit</option>{" "}
                         <option value="LowerDibangValley">
                           Lower Dibang Valley
                         </option>{" "}
                         <option value="LowerSubansiri">Lower Subansiri</option>{" "}
                         <option value="Lucknow">Lucknow</option>{" "}
                         <option value="Ludhiana">Ludhiana</option>{" "}
                         <option value="Lunglei">Lunglei</option>
                         <option value="Madhepura">Madhepura</option>{" "}
                         <option value="Madhubani">Madhubani</option>{" "}
                         <option value="Madurai">Madurai</option>{" "}
                         <option value="MahamayaNagar">Mahamaya Nagar</option>{" "}
                         <option value="Maharajganj">Maharajganj</option>{" "}
                         <option value="Mahasamund">Mahasamund</option>{" "}
                         <option value="Mahbubnagar">Mahbubnagar</option>{" "}
                         <option value="Mahe">Mahe</option>{" "}
                         <option value="Mahendragarh">Mahendragarh</option>{" "}
                         <option value="Mahoba">Mahoba</option>{" "}
                         <option value="Mainpuri">Mainpuri</option>{" "}
                         <option value="Malappuram">Malappuram</option>{" "}
                         <option value="Maldah">Maldah</option>{" "}
                         <option value="Malkangiri">Malkangiri</option>{" "}
                         <option value="Mamit">Mamit</option>{" "}
                         <option value="Mandi">Mandi</option>{" "}
                         <option value="Mandla">Mandla</option>{" "}
                         <option value="Mandsaur">Mandsaur</option>{" "}
                         <option value="Mandya">Mandya</option>{" "}
                         <option value="Mansa">Mansa</option>{" "}
                         <option value="Marigaon">Marigaon</option>{" "}
                         <option value="Mathura">Mathura</option>{" "}
                         <option value="Mau">Mau</option>{" "}
                         <option value="Mayurbhanj">Mayurbhanj</option>{" "}
                         <option value="Medak">Medak</option>{" "}
                         <option value="Meerut">Meerut</option>{" "}
                         <option value="Mehsana">Mehsana</option>{" "}
                         <option value="Mewat">Mewat</option>{" "}
                         <option value="Mirzapur">Mirzapur</option>{" "}
                         <option value="Moga">Moga</option>{" "}
                         <option value="Mokokchung">Mokokchung</option>{" "}
                         <option value="Mon">Mon</option>{" "}
                         <option value="Moradabad">Moradabad</option>{" "}
                         <option value="Morena">Morena</option>{" "}
                         <option value="MumbaiCity">Mumbai City</option>{" "}
                         <option value="Mumbaisuburban">Mumbai suburban</option>{" "}
                         <option value="Munger">Munger</option>{" "}
                         <option value="Murshidabad">Murshidabad</option>{" "}
                         <option value="Muzaffarnagar">Muzaffarnagar</option>{" "}
                         <option value="Muzaffarpur">Muzaffarpur</option>{" "}
                         <option value="Mysore">Mysore</option>
                         <option value="Nabarangpur">Nabarangpur</option>{" "}
                         <option value="Nadia">Nadia</option>{" "}
                         <option value="Nagaon">Nagaon</option>{" "}
                         <option value="Nagapattinam">Nagapattinam</option>{" "}
                         <option value="Nagaur">Nagaur</option>{" "}
                         <option value="Nagpur">Nagpur</option>{" "}
                         <option value="Nainital">Nainital</option>{" "}
                         <option value="Nalanda">Nalanda</option>{" "}
                         <option value="Nalbari">Nalbari</option>{" "}
                         <option value="Nalgonda">Nalgonda</option>{" "}
                         <option value="Namakkal">Namakkal</option>{" "}
                         <option value="Nanded">Nanded</option>{" "}
                         <option value="Nandurbar">Nandurbar</option>{" "}
                         <option value="Narayanpur">Narayanpur</option>{" "}
                         <option value="Narmada">Narmada</option>{" "}
                         <option value="Narsinghpur">Narsinghpur</option>{" "}
                         <option value="Nashik">Nashik</option>{" "}
                         <option value="Navsari">Navsari</option>{" "}
                         <option value="Nawada">Nawada</option>{" "}
                         <option value="Nawanshahr">Nawanshahr</option>{" "}
                         <option value="Nayagarh">Nayagarh</option>{" "}
                         <option value="Neemuch">Neemuch</option>{" "}
                         <option value="Nellore">Nellore</option>{" "}
                         <option value="NewDelhi">New Delhi</option>{" "}
                         <option value="Nilgiris">Nilgiris</option>{" "}
                         <option value="Nizamabad">Nizamabad</option>{" "}
                         <option value="North24Parganas">
                           North 24 Parganas
                         </option>{" "}
                         <option value="NorthDelhi">North Delhi</option>{" "}
                         <option value="NorthEastDelhi">
                           North East Delhi
                         </option>{" "}
                         <option value="NorthGoa">North Goa</option>{" "}
                         <option value="NorthSikkim">North Sikkim</option>{" "}
                         <option value="NorthTripura">North Tripura</option>{" "}
                         <option value="NorthWestDelhi">
                           North West Delhi
                         </option>{" "}
                         <option value="Nuapada">Nuapada</option>
                         <option value="Ongole">Ongole</option>
                         <option value="Osmanabad">Osmanabad</option>
                         <option value="Pakur">Pakur</option>{" "}
                         <option value="Palakkad">Palakkad</option>{" "}
                         <option value="Palamu">Palamu</option>{" "}
                         <option value="Pali">Pali</option>{" "}
                         <option value="Palwal">Palwal</option>{" "}
                         <option value="Panchkula">Panchkula</option>{" "}
                         <option value="Panchmahal">Panchmahal</option>{" "}
                         <option value="PanchsheelNagardistrict(Hapur)">
                           {" "}
                           Panchsheel Nagar district (Hapur){" "}
                         </option>{" "}
                         <option value="Panipat">Panipat</option>{" "}
                         <option value="Panna">Panna</option>{" "}
                         <option value="PapumPare">Papum Pare</option>{" "}
                         <option value="Parbhani">Parbhani</option>{" "}
                         <option value="PaschimMedinipur">
                           Paschim Medinipur
                         </option>{" "}
                         <option value="Patan">Patan</option>{" "}
                         <option value="Pathanamthitta">Pathanamthitta</option>{" "}
                         <option value="Pathankot">Pathankot</option>{" "}
                         <option value="Patiala">Patiala</option>{" "}
                         <option value="Patna">Patna</option>{" "}
                         <option value="PauriGarhwal">Pauri Garhwal</option>{" "}
                         <option value="Perambalur">Perambalur</option>{" "}
                         <option value="Phek">Phek</option>{" "}
                         <option value="Pilibhit">Pilibhit</option>{" "}
                         <option value="Pithoragarh">Pithoragarh</option>{" "}
                         <option value="Pondicherry">Pondicherry</option>{" "}
                         <option value="Poonch">Poonch</option>{" "}
                         <option value="Porbandar">Porbandar</option>{" "}
                         <option value="Pratapgarh">Pratapgarh</option>{" "}
                         <option value="Pratapgarh">Pratapgarh</option>{" "}
                         <option value="Pudukkottai">Pudukkottai</option>{" "}
                         <option value="Pulwama">Pulwama</option>{" "}
                         <option value="Pune">Pune</option>{" "}
                         <option value="PurbaMedinipur">Purba Medinipur</option>{" "}
                         <option value="Puri">Puri</option>{" "}
                         <option value="Purnia">Purnia</option>{" "}
                         <option value="Purulia">Purulia</option>
                         <option value="Raebareli">Raebareli</option>{" "}
                         <option value="Raichur">Raichur</option>{" "}
                         <option value="Raigad">Raigad</option>{" "}
                         <option value="Raigarh">Raigarh</option>{" "}
                         <option value="Raipur">Raipur</option>{" "}
                         <option value="Raisen">Raisen</option>{" "}
                         <option value="Rajauri">Rajauri</option>{" "}
                         <option value="Rajgarh">Rajgarh</option>{" "}
                         <option value="Rajkot">Rajkot</option>{" "}
                         <option value="Rajnandgaon">Rajnandgaon</option>{" "}
                         <option value="Rajsamand">Rajsamand</option>{" "}
                         <option value="RamabaiNagar(KanpurDehat)">
                           {" "}
                           Ramabai Nagar (Kanpur Dehat){" "}
                         </option>{" "}
                         <option value="Ramanagara">Ramanagara</option>{" "}
                         <option value="Ramanathapuram">Ramanathapuram</option>{" "}
                         <option value="Ramban">Ramban</option>{" "}
                         <option value="Ramgarh">Ramgarh</option>{" "}
                         <option value="Rampur">Rampur</option>{" "}
                         <option value="Ranchi">Ranchi</option>{" "}
                         <option value="Ratlam">Ratlam</option>{" "}
                         <option value="Ratnagiri">Ratnagiri</option>{" "}
                         <option value="Rayagada">Rayagada</option>{" "}
                         <option value="Reasi">Reasi</option>{" "}
                         <option value="Rewa">Rewa</option>{" "}
                         <option value="Rewari">Rewari</option>{" "}
                         <option value="RiBhoi">Ri Bhoi</option>{" "}
                         <option value="Rohtak">Rohtak</option>{" "}
                         <option value="Rohtas">Rohtas</option>{" "}
                         <option value="Rudraprayag">Rudraprayag</option>{" "}
                         <option value="Rupnagar">Rupnagar</option>
                         <option value="Sabarkantha">Sabarkantha</option>
                         <option value="Sagar">Sagar</option>
                         <option value="Saharanpur">Saharanpur</option>
                         <option value="Sangrur">Sangrur</option>
                         <option value="SantKabirNagar">
                           Sant Kabir Nagar
                         </option>
                         <option value="SantRavidasNagar">
                           Sant Ravidas Nagar
                         </option>
                         <option value="Saran">Saran</option>
                         <option value="Satara">Satara</option>
                         <option value="Satna">Satna</option>
                         <option value="SawaiMadhopur">Sawai Madhopur</option>
                         <option value="Sehore">Sehore</option>
                         <option value="Senapati">Senapati</option>
                         <option value="Seoni">Seoni</option>
                         <option value="SeraikelaKharsawan">
                           Seraikela Kharsawan
                         </option>
                         <option value="Serchhip">Serchhip</option>
                         <option value="Shahdol">Shahdol</option>
                       </datalist>
                     </div>
                   </div>

                   <div className="col-lg-12">
                     <div className="form-box user-icon mb-30">
                       <div>
                         <h4>Choose Your Package: </h4>
                       </div>
                       <div className="package_row">
                         {/* <label className={`package ${formData.package === '6' ? 'selected' : ''}`}>
                                       <input type="radio" name="package" value="6" className="package" checked={formData.package === '6'} onChange={handleInputChange} />
                                       Test Package<br></br>
                                       <small>Pay- 1/ for 1 Days</small>
                                    </label>

                                    <label className={`package package2 ${formData.package === '1' ? 'selected' : ''}`}>
                                       <input
                                          type="radio"
                                          name="package"
                                          value="1"
                                          className="package"
                                          checked={formData.package === '1'}
                                          onChange={handleInputChange}
                                          
                                       />
                                       1 Visit Package<br />
                                       <small>Pay- 100/ for 1 Days</small>
                                    </label>

                                    <label className={`package package2  ${formData.package === '2' ? 'selected' : ''}`}>
                                       <input type="radio" name="package" value="2" className="package" checked={formData.package === '2'} onChange={handleInputChange} />
                                       2 Visit Package<br></br>
                                       <small>Pay- 200/ for 2 Days</small>
                                    </label>
                                    <label className={`package package2  ${formData.package === '3' ? 'selected' : ''}`}>
                                       <input type="radio" name="package" value="3" className="package" checked={formData.package === '3'} onChange={handleInputChange} />
                                       3 Visit Package<br></br>
                                       <small>Pay- 300/ for 3 Days</small>
                                    </label>
                                    <label className={`package package2  ${formData.package === '4' ? 'selected' : ''}`}>
                                       <input type="radio" name="package" value="4" className="package" checked={formData.package === '4'} onChange={handleInputChange} />
                                       4 Visit Package<br></br>
                                       <small>Pay- 400/ for 4 Days</small>
                                    </label>
                                    <label className={`package package2  ${formData.package === '5' ? 'selected' : ''}`}>
                                       <input type="radio" name="package" value="5" className="package" checked={formData.package === '5'} onChange={handleInputChange} />
                                       5 Visit Package<br></br>
                                       <small>Pay- 500/ for 5 Days</small>
                                    </label> */}
                         <label
                           className={`package ${
                             formData.package === "7" ? "selected" : ""
                           }`}
                         >
                           <input
                             type="radio"
                             name="package"
                             value="7"
                             className="package"
                             checked={formData.package === "6"}
                             onChange={handleInputChange}
                           />
                           1 Visit Package<br></br>
                           <small>Pay- 1000/ for 1 Day</small>
                         </label>
                         <label
                           className={`package ${
                             formData.package === "8" ? "selected" : ""
                           }`}
                         >
                           <input
                             type="radio"
                             name="package"
                             value="8"
                             className="package"
                             checked={formData.package === "6"}
                             onChange={handleInputChange}
                           />
                           10 Visit Package<br></br>
                           <small>Pay- 9000/ for 10 Days</small>
                         </label>
                       </div>
                       {errors.package && (
                         <div className="error-message">{errors.package}</div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-12">
                     <div className="form-box user-icon form2">
                       <h4>For Your First Visit:</h4>
                     </div>
                   </div>
                   <div className="col-lg-6">
                     <div className="form-box user-icon mb-30 form2">
                       <label className="contact-table1">
                         Choose Your Preferred Date{" "}
                       </label>
                       <Flatpickr
                         name="date"
                         className="patientname"
                         id="datepicker"
                         value={formData.date}
                         options={{
                           minDate: tomorrow,
                         }}
                         onChange={handleFlatpickrChange}
                       />
                       {errors.date && (
                         <div className="error-message">{errors.date}</div>
                       )}
                       <div className='payment_mode mt-20'>
                         <h6>
                         Visit Time and Date might vary as per the availability.
                         </h6>
                       </div>
                     </div>
                   </div>
                   <div className="col-lg-6">
                     <div className="form-box user-icon mb-30 form2">
                       <label className="contact-table2">
                         Choose Your Preferred Time{" "}
                       </label>
                       <Flatpickr
                         name="time"
                         className="patientname2"
                         value={formData.time}
                         options={{
                           enableTime: true,
                           noCalendar: true,
                           minTime: "08:00",
                           maxTime: "19:00",
                           minuteIncrement: 15,
                         }}
                         onChange={handleTimeChange}
                       />
                       {errors.time && (
                         <div className="error-message">{errors.time}</div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-12">
                     <div className="form-box user-icon mb-30">
                       <div>
                         <h4>Choose Your Payment Method: </h4>
                       </div>
                       <div className="package_row">
                         <label
                           className={`package ${
                             formData.pay_met === "cash" ? "selected" : ""
                           }`}
                         >
                           <input
                             type="radio"
                             name="pay_met"
                             value="cash"
                             className="package"
                             checked={formData.pay_met === "cash"}
                             onChange={handleInputChange}
                           />
                           BY CASH (OFFLINE)
                         </label>
                         <label
                           className={`package ${
                             formData.pay_met === "online" ? "selected" : ""
                           }`}
                         >
                           <input
                             type="radio"
                             name="pay_met"
                             value="online"
                             className="package"
                             checked={formData.pay_met === "online"}
                             onChange={handleInputChange}
                           />
                           BY CARD (ONLINE)
                         </label>
                       </div>
                       {errors.pay_met && (
                         <div className="error-message">{errors.pay_met}</div>
                       )}
                       <div className='payment_mode'>
                         <h6>
                           For UPI Payments (Google Pay, PhonePe, etc.) select
                           Cash Option.
                         </h6>
                       </div>
                     </div>
                   </div>
                   <div className="col-lg-12">
                     <div className="contact-btn text-center">
                       {/* <button className="primary_btn btn-icon ml-0" type="submit"><span>+</span>Book Your Session</button> */}
                       <button
                         className="primary_btn btn-icon ml-0"
                         type="submit"
                         disabled={loading}
                       >
                         {loading ? (
                           <FaSpinner className="spinner" />
                         ) : (
                           <span>+</span>
                         )}{" "}
                         Book Your Session
                       </button>
                     </div>
                   </div>
                 </div>
               </form>
             </div>
           </div>
         </div>
       </section>
     </>
   );
};

export default BookAForm;