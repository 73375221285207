import React from 'react';
import { Link } from 'react-router-dom';
import ServicesTwoSingleItem from '../../../../components/ServicesTwoSingleItem/ServicesTwoSingleItem';

const ServicesTwoArea = () => {
   return (
      <>
         <section className="servcies-area gray-bg pt-115 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-7 col-lg-8 col-md-12">
                     <div className="section-title pos-rel mb-75">
                        <div className="section-icon">
                           <img className="section-back-icon back-icon-left" src="img/section/section-back-icon.png" alt=""/>
                        </div>
                        <div className="section-text pos-rel">
                           <h5>Our Services</h5>
                           <h1>Elevate Your Quality of Life with Us</h1>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="img/shape/section-title-line.png" alt=""/>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-5 col-lg-4">
                     {/* <div className="section-button text-end d-none d-lg-block pt-80">
                        <Link to="/services"
                           className="primary_btn btn-icon ml-0"><span>+</span>more services</Link>
                     </div> */}
                  </div>
               </div>
               <div className="row">

                  <ServicesTwoSingleItem image="1" name="The ankle is a complex joint that connects the lower leg bones (the tibia and fibula) to the foot bones. " title="Ankle Pain" />
                  <ServicesTwoSingleItem image="2" name="
Back pain is a common medical condition characterized by discomfort in the area of the back." title="Back Pain" />
                  <ServicesTwoSingleItem image="3" name="
Neck pain is discomfort or pain that occurs in area of neck, which includes the soft tissues surrounding it." title="Neck Pain" />
                  <ServicesTwoSingleItem image="4" name="Knee pain can result from various causes, and it is a common complaint that can affect people of all ages. " title="Knee Pain" />
                  <ServicesTwoSingleItem image="5" name="
Shoulder pain refers to discomfort or pain in the shoulder region, which includes the joint, muscles. " title="Sholuder Pain" />
                  <ServicesTwoSingleItem image="6" name="
Hip pain is discomfort or pain in the hip joint, which is a ball-and-socket joint connecting the thigh bone." title="Hip Pain" />
                  <ServicesTwoSingleItem image="7" name="Wrist pain is discomfort or pain in the wrist joint, which connects the forearm bones to the hand." title="Wrist Pain" />
                  <ServicesTwoSingleItem image="8" name="
Elbow pain is discomfort or pain in the elbow joint, which connects the upper arm bone to the two bones." title="Elbow Pain" />

               </div>
            </div>
         </section>
      </>
   );
};

export default ServicesTwoArea;