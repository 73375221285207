import React from 'react';

const ContactArea = () => {
    return (
        <>
            <section className="contact-area pt-60 pb-90" data-background="assets/img/bg/bg-map.png">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div className="contact contactb text-center mb-30 contact-mail">
                                <i className="fas fa-envelope"></i>
                                <h3>Email</h3>
                                <a className='phone_click' href="mailto:contact@bulaao.com" >contact@bulaao.com</a>
                                {/* <a className='phone_click' href="mailto:mahajansaurish@gmail.com" >mahajansaurish@gmail.com</a> */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-map-marker-alt"></i>
                                <h3>Address</h3>
                                <p>44-A, KITCHLU NAGAR,
LUDHIANA, LUDHIANA-141001, PUNJAB
</p>
                            </div>
                        </div>
                        <div className="col-xl-4  col-lg-4 col-md-12 ">
                            <div className="contact text-center mb-30 contact-phone">
                                <i className="fas fa-phone"></i>
                                <h3>Phone</h3>
                                <a className='phone_click' href="tel: 6262191979">+91 6262191979</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactArea;