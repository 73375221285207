import React from 'react';
import { Link } from 'react-router-dom';
// import HomeSingleFooterBlog from '../HomeSingleFooterBlog/HomeSingleFooterBlog';

const Footer = () => {
   return (
      <>
                  <footer>
            <div className="footer-top h4_footer primary-bg footer-map pos-rel pt-20 pb-15">
               <div className="container">
                  <div className='row'>
                     <div className='col-12 footer-logo-section'>
                           <div className="footer-logo home-footer-logo mb-20">
                              <Link to="/"><img src="img/logo/footer_logo.svg" alt=""/></Link>
                           </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12">
                        <div className="footer-widget">
                           <div className="footer-menu">
                              <ul className='footer-menu-items'>
                                 <li><Link to="/">Home |</Link></li>
                                 <li><Link to="/">About Us |</Link></li>
                                 <li><Link to="/">Contact Us</Link></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-12 col-lg-6 col-md-12 opening_hours">
                        <div className="footer-widget h4footer-widget ">
                           <div className="h4events-list">
                              <div>
                              <ul>
                                 <li><i className="fal fa-clock"></i><span>Monday - Sunday   (08:00 am - 10:00 pm)</span></li>
                              </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-12 col-lg-6 col-md-12 opening_hours">
                        <div className="footer-widget h4footer-widget ">
                           <div className="h4events-list">
                              <div className="h4footer-social">
                              <ul className="list-inline">
                                 <li><a href="#"><i className="fab fa-facebook-f footer-social-icon"></i></a></li>
                                 <li><a href="#"><i className="fab fa-twitter footer-social-icon"></i></a></li>
                                 <li><a href="#"><i className="fab fa-google footer-social-icon"></i></a></li>
                              </ul>
                           </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-12 col-lg-6 col-md-12">
                     <div className="container">
                  <div className="row">
                     <div className="col-xl-12 col-lg-6 col-md-12 justify-content-center mb-10">
                        <div className="footer-copyright footer-copyright-3 text-center">
                           <p>© 2023 Bulaao.com, All Rights Reserved</p>
                        </div>
                     </div>
                     <div className="col-xl-12 col-lg-6 col-md-12 justify-content-center">
                        <div className="footer-copyright footer-copyright-3 text-center button_margin">
                           <p>Designed & Developed by Cyberframe</p>
                        </div>
                     </div>
                  </div>
               </div>
                     </div>
                  </div>
               </div>
               <div id="whatsapp-icon">
                                          <Link to="/BookASession" className="primary_btn book_button">Book A Session</Link>
</div>
            </div>
         </footer>
      </>
   );
};

export default Footer;