import React from 'react';
import { Link } from 'react-router-dom';
// import useGlobalContext from '../../hooks/useGlobalContext';

const HomeHeroSingleSlide = ({ bg_className}) => {
  //  const videoPopup = useGlobalContext();
  //  const {setIsOpen} = videoPopup
   return (
     <>
       <div className="banner">
         <div className="hme_banner"></div>
       </div>
       {/* <div className={`single-slider slider-height slider_bg_${bg_className}`}></div> */}
       {/* <div className="slider_bg">your component content</div> */}
     </>
   );
};

export default HomeHeroSingleSlide;