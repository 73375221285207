import React from 'react';
import HomeFourSingleHowWorksItem from '../../../components/HomeFourSingleHowWorksItem/HomeFourSingleHowWorksItem';

const HomeFourHowWorks = () => {
   return (
     <>
       <section className="howitworks pt-5 pb-5">
         <div className="container">
           <div className="row">
             <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
               <div class="section-title  founder-section mb-5">
                 <div class="img-container section-text">
                   <img
                     class="img"
                     src="img/section/section-back-icon.png"
                     alt=""
                   />
                   <h6 class="text-on-image-top">Why Choose Bulaao</h6>
                   <h1 class="text-on-image">
                     Treatment that blends Competence with Compassion
                   </h1>
                 </div>
                 <div class="section-line pos-rel">
                   <img src="img/shape/section-title-line.png" alt="" />
                 </div>
               </div>
             </div>
           </div>
           <div className="row pos-rel d-flex justify-content-between">
             <HomeFourSingleHowWorksItem
               icon="1"
               title="Treatment scheduling"
               moveIcon="Instant confirmation of clinic appointment & home visit is confirmed within 30 mins. We try our best to not change a physio for ongoing treatments."
             />
             <HomeFourSingleHowWorksItem
               icon="2"
               title="Payments"
               moveIcon="Transparent Pricing with friendly packages for long term treatments. Option to pay through cash, card or online with Insurance friendly billing."
             />
             <HomeFourSingleHowWorksItem
               icon="3"
               title="12*7 Support"
               moveIcon="Help is just a text Away. 12*7 support on WhatsApp for any query or concern for your new appointment sorfor an ongoing physiotherpay treatments."
             />
           </div>
         </div>
       </section>
     </>
   );
};

export default HomeFourHowWorks;