import React from 'react';
import { Link } from 'react-router-dom';

const CommonPageHeader = ({title,subtitle}) => {
   return (
     <>
       <div className="banner-bg">
       <div className="row banner_row">
             <div className="col-lg-12 col-md-12">
               <div className="page-title">
                 <h1 style={{ color: "white" }}>{title}</h1>
               </div>
             </div>
           </div>
         <div className="hme_banner"></div>
       </div>
       {/* <section className="breadcrumb-bg pt-200 page_header_bg">
         <div className="container">
           <div className="row">
             <div className="col-lg-9 col-md-9">
               <div className="page-title">
                 <p
                   className="small-text pb-15"
                   style={{
                     color: "white",
                     position: "relative",
                     left: "10px",
                   }}
                 >
                   We are here for your care.
                 </p>
                 <h1 style={{ color: "white" }}>{title}</h1>
               </div>
             </div>
           </div>
         </div>
       </section> */}
     </>
   );
};

export default CommonPageHeader;