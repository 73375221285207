import React from 'react';
import Slider from "react-slick";
import HomeHeroSingleSlide from '../../../../components/HomeHeroSingleSlide/HomeHeroSingleSlide';
import VideoPopup from '../../../../components/VideoPopup/VideoPopup';
import useGlobalContext from '../../../../hooks/useGlobalContext';

const HomeHeroSection = () => {

   

   return (
      <>

         <VideoPopup videoId="oU_GUAWz52w" />

         <section className="hero-area">
            <div className="hero-slider">

               <Slider className='slider-active'>

                  <HomeHeroSingleSlide bg_className="1" />

                  {/* <HomeHeroSingleSlide bg_className="2" /> */}

               </Slider>

            </div>
         </section>
      </>
   );
};

export default HomeHeroSection;