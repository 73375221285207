import React from "react";
import { Link } from "react-router-dom";
import HomeSingleTeam from "../../../../components/HomeSingleTeam/HomeSingleTeam";

const HomeOurTeam = () => {
  return (
    <>
      <section className="team-area pt-3 pb-55">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div class="section-title  founder-section mb-5">
                <div class="img-container section-text">
                  <img
                    class="img"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                  {/* <h6 class="text-on-image-top">Our Founders</h6> */}
                  <h1 class="text-on-image">Our Founders</h1>
                </div>
                <div class="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className="col-xl-6 col-lg-5">
              <div className="section-button text-end d-none d-lg-block pt-80 team_btn">
                <Link
                  to="/"
                  className="primary_btn btn-icon ml-0 founder_book_a_session"
                >
                  <span>+</span>Book A Session
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <HomeSingleTeam
              image="1"
              title="Krishiv Mahajan"
              subtitle="Founder"
            />
            <HomeSingleTeam
              image="2"
              title="Saurish mahajan"
              subtitle="Founder"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeOurTeam;
