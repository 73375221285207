import React from 'react';

const CommonSectionArea = ({ area_header }) => {
   return (
      <>
         <section className="about-area pt-60 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-5">
                     {area_header}
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="about-right-side  mb-30" style={{ paddingTop: "5px" }}>
                        <div className="about-title mb-20">
                           <h5>About Us</h5>
                           <h1 style={{ fontsize: "42px" }}>Why did we start BULAAO</h1>
                        </div>
                        <div className="about-text mb-20">
                           <p>
                              In 2019, our family faced a challenge when our grandfather had a crucial operation. Despite excellent hospital care, the struggle continued at home. Recognizing the gap in post-hospitalization care in Ludhiana, my brother and I launched a mission. The result? Our website/app connects patients with qualified healthcare professionals for post-operative care, rehabilitation, and companionship. Join us in revolutionizing medical care, creating a stress-free experience for all.</p>
                        </div>
                        <div className="our-destination">
                           <div className="single-item">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-1.png" alt="" />
                              </div>
                              <div className="fix">
                                 <h3>Our Mission</h3>
                                 <p>
                                    Bulaao's mission is to revolutionize post-hospitalization care, providing seamless access to diverse healthcare services. We unify facilities, offering convenience, trust, and personalized care for a healthier, happier community.
                                 </p>
                              </div>
                           </div>
                           <div className="single-item">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-2.png" alt="" />
                              </div>
                              <div className="fix">
                                 <h3>Our Vision</h3>
                                 <p>
At Bulaao, we envision a future where post-hospitalization care is organized, accessible, and personalized. We aim to lead the transformation of healthcare into a harmonized ecosystem, empowering individuals for excellence in post-treatment support.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonSectionArea;