import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';
import { useNavigate } from 'react-router-dom';

const Joinus= () => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [formData, setFormData] = useState({
      company_name: '',
      name: '',
      designation: '',
      ph_no: '',
      email: '',
      message: ''
   });
   const [errors, setErrors] = useState({});

   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
         ...formData,
         [name]: value,
      });

      setErrors({
         ...errors,
         [name]: undefined,
      });
   };

   const validateForm = () => {
      const newErrors = {};

      if (!formData.company_name.trim()) {
         newErrors.company_name = 'Company name field is required!';
      }
      if (!formData.name.trim()) {
         newErrors.name = 'Name field is required!';
      }
      if (!formData.designation.trim()) {
         newErrors.designation = 'Designation field is required!';
      }
      if (!formData.ph_no.trim()) {
         newErrors.ph_no = 'Phone no is required!';
      }
      if (!formData.email.trim()) {
         newErrors.email = 'Email field is required!';
      }
      if (!formData.message.trim()) {
         newErrors.message = 'Message field is required!';
      }

      setErrors(newErrors);

      let firstErrorField = Object.keys(newErrors)[0];
      firstErrorField = document.getElementsByName(firstErrorField)[0];
      if(firstErrorField) firstErrorField.focus();

      return Object.keys(newErrors).length === 0;
   };

   const handleSubmit = async (e) => {
      e.preventDefault();

      if (validateForm()) {
         try {
            setLoading(true);

            const response = await axios.post(`${API_BASE_URL}/send_join_us_mail`, formData);
            if (response.status === 200 && !response.data.errors) {
               showStatus(true);
               navigate(`/thankyou?message=Our team will contact you soon.`);
            }
            else {
               showStatus(false);
               console.log(response.data.errors);
            }
         }
         catch (error) {
            showStatus(false);
            console.log(error);
         }
         finally{
            setLoading(false);
         }
      }
   };

   const showStatus = (status) => {
      setTimeout(() => {
         const response_message = document.getElementById('res_status');

         response_message.innerHTML = '';

         if (status) {
            response_message.innerHTML = '&#10003;';
            response_message.style.backgroundColor = 'rgba(144, 238, 144, 1)';
         } else {
            response_message.innerHTML = '&#10007;';
            response_message.style.backgroundColor = 'rgba(255, 99, 71, 1)';
         }
         
      }, 300);
    };

   return (
      <>
       <section className="contact-form-area gray-bg pt-40 pb-100">
            <div className="container">
               <div className="form-wrapper">
                  <div className="row align-items-center">
                     <div className="col-xl-8 col-lg-8">
                        <div className="section-title mb-20">
                           <p><span></span>Contact Our Physiotherapy Team</p>
                           <h1>Collaborate for success</h1>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-3 d-none d-xl-block ">
                        <div className="section-link mb-80 text-end">
                        </div>
                     </div>
                  </div>
                  <div className="contact-form">
                     <form id="contact-form" onSubmit={handleSubmit}>
                        <div className="row">
                           <div className="col-lg-6">
                              <div className="form-box user-icon mb-30">
                                 <input type="text" name="company_name" placeholder="Enter Your Company Name" value={formData.company_name} onChange={handleInputChange}/>
                                 {errors.company_name && <div className="error-message">{errors.company_name}</div>}
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box subject-icon mb-30">
                                 <input type="text" name="name" placeholder="Enter Your Name" value={formData.name} onChange={handleInputChange}/>
                                 {errors.name && <div className="error-message">{errors.name}</div>}
                              </div>
                           </div>
                           <div className="col-lg-4">
                              <div className="form-box subject-icon mb-30">
                                 <input type="text" name="designation" placeholder="Enter Your Designation" value={formData.designation} onChange={handleInputChange}/>
                                 {errors.designation && <div className="error-message">{errors.designation}</div>}
                              </div>
                           </div>
                           <div className="col-lg-4" >
                              <div className="form-box subject-icon mb-30">
                                 <input type="number" name="ph_no" placeholder="Enter Your Phone Number" value={formData.ph_no} onChange={handleInputChange}/>
                                 {errors.ph_no && <div className="error-message">{errors.ph_no}</div>}
                              </div>
                           </div>
                           <div className="col-lg-4">
                              <div className="form-box subject-icon mb-30">
                                 <input type="email" name="email" placeholder="Enter Your Email Address" value={formData.email} onChange={handleInputChange}/>
                                 {errors.email && <div className="error-message">{errors.email}</div>}
                              </div>
                           </div>
                           <div className="col-lg-12">
                              <div className="form-box message-icon mb-30">
                                 <textarea name="message" id="message" cols="30" rows="10"
                                    placeholder="Write Your Message"value={formData.message} onChange={handleInputChange}></textarea>
                                 {errors.message && <div className="error-message">{errors.message}</div>}
                              </div>
                              <div className="contact-btn text-center">
                                 {/* <div id="response_message"></div> */}
                                 <button className="primary_btn btn-icon ml-0" type="submit" disabled={loading}>
                                    {loading ? <FaSpinner className="spinner" /> : <span id='res_status'>+</span>} Join With Us
                                 </button>
                              </div>
                           </div>
                        </div>
                     </form>
                     <p className="ajax-response text-center"></p>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Joinus;