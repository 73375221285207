import React from 'react';
import { Link } from 'react-router-dom';
import HomeTwoSingleAbout from '../../../../components/HomeTwoSingleAbout/HomeTwoSingleAbout';

const HomeAboutArea = () => {
   return (
      <>
         <section className="about-area about-area-mid pt-60 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-12 col-md-12">
                     <div className="row">

                        <HomeTwoSingleAbout name="Comprehensive healthcare services, post-op care, rehab, qualified nurses, companionship." icon="1" title="
Holistic Healthcare" spacing="b-40" />
                        <HomeTwoSingleAbout name="User-friendly interface, curated professionals, stress-free caregiver search." icon="2" title="
Intuitive Interface" spacing="t-40" />
                        <HomeTwoSingleAbout name="Verified, licensed caregivers for confident, high-quality healthcare choices." icon="3" title="
Certified Specialists" spacing="b-30" />
                        <HomeTwoSingleAbout name="Transforming healthcare, addressing post-hospitalization challenges for a seamless experience." icon="4" title="
Healthcare Revolution" spacing="t-40 mb-30" />

                     </div>
                  </div>

                  <div className="col-xl-6 col-lg-12 col-md-11">
                     <div className="about-right-side pt-25 mb-30">
                        <div className="about-title mb-20">
                           <h5 className="pink-color">Why Choose Us</h5>
                           <h1>Short Story Of Bulaao</h1>
                        </div>
                        <div className="about-text">
                           <p>Bulaao offers comprehensive healthcare solutions, covering post-operative care, rehabilitation, and companionship in one convenient platform. Families can easily connect with highly qualified nurses and healthcare providers, ensuring personalized care for their loved ones. The user-friendly interface simplifies the process, allowing individuals to browse and select caregivers effortlessly. Bulaao guarantees the vetting and qualification of every professional, providing peace of mind to families. By choosing Bulaao, individuals actively participate in transforming healthcare accessibility, addressing post-hospitalization challenges, and contributing to a community dedicated to stress-free, quality healthcare for all.</p><br />
                        </div>
                        <div className="about-text-list mb-40">
                           <ul>
                              <li><i className="fa fa-check"></i><span className='choose_subcontent'>One-stop destination for comprehensive healthcare solutions.</span></li>
                              <li><i className="fa fa-check"></i><span className='choose_subcontent'>Simple, stress-free browsing with user-friendly interface.</span></li>
                              <li><i className="fa fa-check"></i><span className='choose_subcontent'>Vetted, qualified professionals ensuring high-quality care services.</span></li>
                           </ul>
                        </div>
                        <div className="button-area">
                           <Link to="/" className="primary_btn btn-icon ml-0"><span>+</span>learn more</Link>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeAboutArea;