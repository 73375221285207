import React from 'react';
import AboutSingleCounter from '../../../components/AboutSingleCounter/AboutSingleCounter';

const AboutCounter = () => {
   return (
      <>
      
         <section className="counter-wraper pt-4 pb-90 gray-bg">
            <div className="container">
               <div className="row">

                  <AboutSingleCounter icon="1" counter="Go for result-oriented physiotherapy at a fully-equipped Bulaao physiotherapy clinic nearby in your own locality" title="Physiotherapy Near Me" />
                  <AboutSingleCounter icon="2" counter="Recover with expert physiotherapy at home conveniently with a home visit physiotherapist" title="Physiotherapy at Home" />
                  <AboutSingleCounter icon="3" counter="See a Physio online, so you need not come to clinic! Tele Physiotherapy which is Effective, Convenient & Safe" title="Online Physiotherapy" />

               </div>
            </div>
         </section>
      </>
   );
};

export default AboutCounter;