

import React from 'react';
// import HomeSinglePrice from '../../../../components/HomeSinglePrice/HomeSinglePrice';
import HomeTwoSingleTestimonial from '../../../../components/HomeTwoSingleTestimonial/HomeTwoSingleTestimonial';
import Slider from "react-slick";

const HomePricing = () => {
   const settings = {
      autoplay: true,
      autoplaySpeed: 2000,
      dots: false,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               infinite: true,
            }
         },
         {
            breakpoint: 991,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false
            }
         },
      ]

   };
   return (
     <>
       <div className="testimonials-area pb-5">
         <div className="container">
           <div className="row">
             {/* <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1 col-md-10 offset-md-1">
               <div className="section-title text-center pos-rel mb-70 img-container">
                 
                   <img
                     className="section-back-icon"
                     src="img/section/section-back-icon.png"
                     alt=""
                   />
         
                 <div className="section-text pos-rel">
                   <h5>Testimonials</h5>
                   <h1>What Our Patients Say About us</h1>
                 </div>
                 <div className="section-line pos-rel">
                   <img src="img/shape/section-title-line.png" alt="" />
                 </div>
               </div>
             </div> */}

             <div class="section-title  founder-section mb-5">
               <div class="img-container section-text">
                 <img
                   class="img"
                   src="img/section/section-back-icon.png"
                   alt=""
                 />
                 <h6 class="text-on-image-top">Testimonials</h6>
                 <h1 class="text-on-image">What Our Patients Say About us</h1>
               </div>
               <div class="section-line pos-rel">
                 <img src="img/shape/section-title-line.png" alt="" />
               </div>
             </div>
           </div>

           <Slider className="testimonials-activation" {...settings}>
             <HomeTwoSingleTestimonial
               image="1"
               name="Outstanding care at Bulaao! Expert therapists and personalized treatments made a remarkable difference in my life."
               title="Ananya Reddy"
             />
             <HomeTwoSingleTestimonial
               image="2"
               name="As a long-time sufferer of chronic pain, I was skeptical about physiotherapy Bulaao changed my perspective completely."
               title="Vikram Choudhary"
             />
             <HomeTwoSingleTestimonial
               image="3"
               name="The therapists at Bulaao provided me with tailored treatment plans that produced tangible results. I'm back on my feet, thanks to them."
               title="Alok Sharma"
             />
           </Slider>
         </div>
       </div>
     </>
   );
};

export default HomePricing;
