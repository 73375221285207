import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import BookASession from '../../../pages/BookASession/BookASession';
import Contact from '../../../pages/Contact/Contact';
import ServicesTwo from '../../../pages/Services/ServicesTwo/ServicesTwo';
import AboutUs from '../../../pages/AboutUs/AboutUs';
import HomeHeader from '../../../pages/Home/Home/HomeHeader/HomeHeader';

const Sidebar = ({ show, handleClose }) => {
  return (
    <>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="side__bar"
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='side_bar'>
              <ul>
                <li className="logos">
                  <img src="img/logo/footer_logo.svg" alt="" />
                </li>
                <li>
                  <NavLink to="/home">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about">About Us</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/servicesTwo">All services</NavLink>
                </li> */}
                <li>
                  <NavLink to="/contact">Contact Us</NavLink>
                </li>
                <li>
                  <NavLink to="/Joinwithus">Join Us</NavLink>
                </li>
                <li>
                  <NavLink to="/BookASession">Book a Session</NavLink>
                </li>
              </ul>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Sidebar;