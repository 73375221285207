import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import Footer from '../../components/Shared/Footer';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
    const navigate = useNavigate();

    useEffect(()=>{
        const getParams = async () => {
            const txn_id = new URLSearchParams(window.location.search).get(
                "txn_id"
            );
            
            const order_id = new URLSearchParams(window.location.search).get(
                "order_id"
            );

            const message = new URLSearchParams(window.location.search).get(
                "message"
            );
            
            setTransactionId(txn_id);
            setOrderId(order_id);
            setMessage(message);
        }

        getParams();
    })

    const [transactionId, setTransactionId] = useState('');
    const [orderId, setOrderId] = useState('');
    const [message, setMessage] = useState('');

    if(!transactionId && !orderId && !message){
        navigate('/');
    }

  return (
    <>
        <HomeHeader/>
        {/* <CommonPageHeader title="ThankYou" subtitle="" /> */}
        <div className='thank_you_container'>
            <div className='thank_you_card'>
                <h1>Thank You for Choosing Bulaao</h1>
                {message && <p>{message}</p>}
                {transactionId && <p>Your Transaction Id is: {transactionId}</p>}
                {orderId && <p>Your Booking Id is: {orderId}</p>}
                {/* <Link to="/">Go to Dashboard</Link> */}
            </div>
        </div>
        <Footer/>
    </>
  );
};

export default ThankYou;