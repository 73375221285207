import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaSpinner } from "react-icons/fa";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import { useNavigate } from "react-router-dom";

const ContactFormArea = () => {
    const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [formData, setFormData] = useState({
      name: '',
      address: '',
      phone: '',
      email: '',
      message: '',
   });

   const [errors, setErrors] = useState({});

   const validateForm = () => {
      const newErrors = {};

      if (!formData.name.trim()) {
         newErrors.name = 'Name field is required!';
      }
      if (!formData.address.trim()) {
         newErrors.address = 'Address field is required!';
      }
      if (!formData.phone.trim()) {
         newErrors.phone = 'Phone field is required!';
      }
      if (!formData.email.trim()) {
         newErrors.email = 'Email field is required!';
      }
      if (!formData.message.trim()) {
         newErrors.message = 'Message field is required!';
      }

      setErrors(newErrors);

      return Object.keys(newErrors).length === 0;
   };

   const handleSubmit = async(e) => {
      e.preventDefault();

      if (validateForm()) {
         // Your form submission logic here
          try {
            setLoading(true);

            const response = await axios.post(`${API_BASE_URL}/send_contact_us_mail`, formData);
            if (response.status === 200 && !response.data.errors) {
               showStatus(true);
               navigate(`/thankyou?message=Our team will contact you soon.`);
            }
            else {
               showStatus(false);
               console.log(response.data.errors);
            }
         }
         catch (error) {
            showStatus(false);
            console.log(error);
         }
         finally{
            setLoading(false);
         }
      }
   };

   const handleChange = (e) => {
      setFormData({
         ...formData,
         [e.target.name]: e.target.value,
      });

      // Clear the corresponding error when the user starts typing in a field
      setErrors({
         ...errors,
         [e.target.name]: '',
      });
   };
    const showStatus = (status) => {
      setTimeout(() => {
        const response_message = document.getElementById("res_status");

        response_message.innerHTML = "";

        if (status) {
          response_message.innerHTML = "&#10003;";
          response_message.style.backgroundColor = "rgba(144, 238, 144, 1)";
        } else {
          response_message.innerHTML = "&#10007;";
          response_message.style.backgroundColor = "rgba(255, 99, 71, 1)";
        }
      }, 300);
    };

   return (
     <>
       <section className="contact-form-area gray-bg pt-40 pb-100">
         <div className="container">
           <div className="form-wrapper">
             <div className="row align-items-center">
               <div className="col-xl-8 col-lg-8">
                 <div className="section-title">
                   <p>
                     <span></span>Contact Our Physiotherapy Team
                   </p>
                   <h1>Have An Issue ?</h1>
                 </div>
               </div>
               <div className="col-xl-4 col-lg-3 d-none d-xl-block ">
                 <div className="section-link mb-80 text-end">
                   {/* <Link to="/appoinment"
                              className="primary_btn btn-icon ml-0"><span>+</span>Make
                              Appointment</Link> */}
                 </div>
               </div>
             </div>
             <div className="contact-form">
               <form id="contact-form" onSubmit={handleSubmit}>
                 <div className="row">
                   <div className="col-lg-6">
                     <div className="form-box user-icon mb-30">
                       <input
                         type="text"
                         name="name"
                         placeholder="Your Name"
                         value={formData.name}
                         onChange={handleChange}
                       />
                       {errors.name && (
                         <div className="error-message">{errors.name}</div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-6">
                     <div className="form-box subject-icon mb-30">
                       <input
                         type="text"
                         name="address"
                         placeholder="Your Address"
                         value={formData.address}
                         onChange={handleChange}
                       />
                       {errors.address && (
                         <div className="error-message">{errors.address}</div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-6">
                     <div className="form-box subject-icon mb-30">
                       <input
                         type="number"
                         name="phone"
                         placeholder="Your Phone Number"
                         value={formData.phone}
                         onChange={handleChange}
                       />
                       {errors.phone && (
                         <div className="error-message">{errors.phone}</div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-6">
                     <div className="form-box subject-icon mb-30">
                       <input
                         type="email"
                         name="email"
                         placeholder="Your Email Address"
                         value={formData.email}
                         onChange={handleChange}
                       />
                       {errors.email && (
                         <div className="error-message">{errors.email}</div>
                       )}
                     </div>
                   </div>
                   <div className="col-lg-12">
                     <div className="form-box message-icon mb-30">
                       <textarea
                         name="message"
                         id="message"
                         cols="30"
                         rows="10"
                         placeholder="Write Your Message"
                         value={formData.message}
                         onChange={handleChange}
                       />
                       {errors.message && (
                         <div className="error-message">{errors.message}</div>
                       )}
                     </div>
                     <div className="contact-btn text-center">
                       <button
                         className="primary_btn btn-icon ml-0"
                         type="submit"
                         disabled={loading}
                       >
                         {loading ? (
                           <FaSpinner className="spinner" />
                         ) : (
                           <span id="res_status">+</span>
                         )}
                         Contact Us
                       </button>
                     </div>
                   </div>
                 </div>
               </form>
               <p className="ajax-response text-center"></p>
             </div>
           </div>
         </div>
       </section>
     </>
   );
};


export default ContactFormArea;