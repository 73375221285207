import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/Shared/Footer';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import Joinus from './Joinus/Joinus';

const Joinwithus = () => {
    return (
        <>
            <HomeHeader/>
            <CommonPageHeader title="Join With Us" subtitle="Contact" />
            <Joinus/>
            <Footer/>
        </>
    );
};

export default Joinwithus;